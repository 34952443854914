import React from 'react';
import axios, { AxiosError } from 'axios';
import validator from 'validator';
import { csrfToken } from '../../common/phxSecurity';
import InfoPopUp from '../common/InfoPopUp';

interface Props {
  forceLocal?: boolean;
  noOAuth?: boolean;
  passwordResetUrl: string;
}

interface State {
  email: string;
  password: string;
  isPhoenixUser: boolean;
  submitted: boolean;
  error: string | null;
  showForm: boolean;
}

export class LoginForm extends React.Component<Props, State> {
  phoenixDomain = "phoenixtma.com";

  loginPath = "/session";
  samlLoginPath = "/users/saml/sign_in";

  infoMessage = this.props.noOAuth ? "Setting up Google or Microsoft for sign in recommended" : "Signing in with Google or Microsoft recommended when possible"

  constructor(props: Props) {
    super(props);
    this.state = { isPhoenixUser: false, email: "", password: "", submitted: false, error: null, showForm: this.props.noOAuth || false };
  }

  toggleForm = (event: React.FormEvent<HTMLButtonElement>): any => {
    event.preventDefault();
    this.setState({ showForm: !this.state.showForm });
  }

  redirectToPasswordReset = (event: React.FormEvent<HTMLButtonElement>): any => {
    event.preventDefault();
    return window.location.assign(this.props.passwordResetUrl);
  }

  loginButtonText = (): any => {
    if (this.state.submitted) return (
      <span>
        <span className="pr-2">{this.isSamlLogin() ? 'Redirecting' : 'Logging in'}</span>
        <span className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">...</span>
        </span>
      </span>
    );
    return this.isSamlLogin() ? "Continue with SSO" : "Log in";
  }

  isPhoenixEmail = (email: string): boolean => {
    const domain = email.split("@").pop();
    if (!domain) return false;
    return domain.toLowerCase() == this.phoenixDomain;
  }

  isSamlLogin = (): boolean => {
    if (this.props.forceLocal) return false;
    return this.state.isPhoenixUser;
  }

  submittable = (): boolean => {
    if (this.state.submitted) return false;
    if (!validator.isEmail(this.state.email)) return false;
    if (this.isSamlLogin()) return true;
    return this.state.password.length > 0;
  }

  submitPath = (): string => {
    return this.isSamlLogin() ? this.samlLoginPath : this.loginPath;
  }

  submitParams = (): any => {
    let params = { email: this.state.email };
    if (!this.isSamlLogin()) Object.assign(params, { password: this.state.password });
    return params;
  }

  handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    let state = { email: event.currentTarget.value, isPhoenixUser: this.isPhoenixEmail(event.currentTarget.value) };
    if (this.isSamlLogin()) Object.assign(state, { password: "" });

    this.setState(state);
  }

  handlePasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ password: event.currentTarget.value });
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ submitted: true });

    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken();
    axios.post(this.submitPath(), this.submitParams(), { headers: { 'Accept': 'application/json' } })
      .then((response) => {
        if (this.isSamlLogin()) return window.location.assign(response.data.idp_url);
        return window.location.assign(response.data.url);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status == 401) return this.setState({ error: "Invalid username/password", submitted: false });
        this.setState({ error: "Server encountered an error.  Please try again later", submitted: false });
      });
  }

  renderError = () => {
    if (!this.state.error) return;
    return <div className="alert alert-danger">{this.state.error}</div>
  }

  renderPasswordField = () => {
    if (this.isSamlLogin()) return;
    return (
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input type="password" className="form-control" id="password" placeholder="password" autoComplete="current-password" onChange={this.handlePasswordChange} />
      </div>
    );
  }

  render() {
    return (
      <div className="mt-3">
        <div className="d-flex flex-row align-items-center justify-content-center">
          <div className="login-info">
            <InfoPopUp placement="left" iconSize="25px" message={this.infoMessage} />
          </div>
          <div className="login-link ml-2">
            <a href="#" onClick={this.toggleForm}>Sign in with Password</a>
          </div>
        </div>

        {this.state.showForm ? (
          <div className="login-form">
            <div className="d-flex justify-content-center">
              {this.renderError()}
            </div>
            <div className="d-flex justify-content-center">
              <form className="text-left mb-2" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" className="form-control" id="email" placeholder="email@example.com" autoFocus={true} autoComplete="email" value={this.state.email} onChange={this.handleEmailChange} />
                </div>
                {this.renderPasswordField()}
                <button type="submit" className="btn btn-primary" disabled={!this.submittable()}>{this.loginButtonText()}</button>
              </form>
            </div>
            <div className='mt-2 login-link'>
              <a href="#" onClick={this.redirectToPasswordReset}><em>Forgot your password?</em></a>
            </div>
          </div>
        ) : (
          null
        )}
      </div>
    );
  }
}

export default LoginForm;
