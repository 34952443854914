export function numberWithDelimiter(num: (number|string), precision: number = 0): string {
  return Intl.NumberFormat('en-US', { minimumFractionDigits: precision, maximumFractionDigits: precision }).format(toFloat(num));
}

export function numberToCurrency(num: (number|string), precision: number = 0): string {
  return '$' + Intl.NumberFormat('en-US', { currency: 'USD', minimumFractionDigits: precision, maximumFractionDigits: precision }).format(toFloat(num));
}

export function numberToCompactCurrency(num: (number|string), precision: number = 3): string {
  return '$' + Intl.NumberFormat('en-US', { notation: 'compact', currency: 'USD', minimumFractionDigits: precision, maximumFractionDigits: precision }).format(toFloat(num));
}

export function numberToPercentage(num: (number|string), precision: number = 3): string {
  return toFloat(num).toFixed(precision) + '%';
}

export function numberToMultiple(num: (number|string), precision: number = 3): string {
  return toFloat(num).toFixed(precision) + 'x';
}

export function toFloat(value: (number|string)): number {
  if (typeof value === 'string') value = parseFloat(value);
  return value;
}

function truncatedIntlNumberFormat(formatted: string, precision: number = 0) {
  const matched = formatted.match(/(.+)\.(\d+)(\w+)?|(.+)/);
  if (!matched) return formatted;

  const compactKey = matched[3] || '';
  if (precision == 0) return matched[1] + compactKey;

  const decimal = matched[2] || '';
  return matched[1] + "." + decimal.substring(0, precision) + compactKey;
}
